<template>
  <div style="background-color: #ffe1c5">
    <div class="project3TopContainer">
      <div class="imgcontainer">
        <div class="img">
          <img
            src="https://asset.fsytss.com/trace/wcj/icon_logo_twice_1.png"
            alt=""
          />
        </div>
      </div>
      <div class="img2">
        <img
          src="https://asset.fsytss.com/trace/wcj/icon_product_2.png"
          alt=""
        />
      </div>
      <div class="dateAndInfoContainer">
        <div class="datecontainer">
          <div class="datelogo">
            <img
              src="https://asset.fsytss.com/trace/wcj/icon_product_3.png"
              alt=""
            />
          </div>
          <div class="date">
            <div class="dateDetailContainer">
              <div class="dateDetail">{{ data.cycleDay }}</div>
              <div class="dateTailText">天</div>
            </div>
            <div class="tianshuText">养殖天数</div>
          </div>
        </div>
      </div>
      <div class="infocontainer2">
        <div class="info_bottom">
          <img src="https://asset.fsytss.com/trace/wcj/bg_verify_1.png" />
          <div class="infodetail">
            <div
              style="
                font-size: 15px;
                color: #231815;
                font-weight: bold;
                line-height: 16px;
              "
            >
              {{ "真品验证通过！" }}
            </div>
            <div
              style="
                display: flex;
                color: #000;
                margin-top: 8px;
                font-size: 10px;
                line-height: 12px;
              "
            >
              <div style="width: 50px">查询次数:</div>
              <div>{{ data.scanNumber }}次</div>
            </div>
            <div
              style="
                display: flex;
                color: #000;
                margin-top: 4px;
                font-size: 10px;
                line-height: 12px;
                /* width: calc(100vw - 132px); */
                width: 50px;
              "
            >
              <div style="width: 50px">溯 源 I D:</div>
              <div
                style="
                  flex: 1;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-break: break-all;
                "
              >
                <!-- {{ data.manufactureOrderContractId }} -->
                {{ data.tracingNo }}
                <!-- 222 -->
              </div>
            </div>
            <div
              style="
                display: flex;
                color: #000;
                margin-top: 4px;
                font-size: 10px;
                line-height: 12px;
              "
            >
              <div style="width: 50px">批次编号:</div>
              <div>
                {{ data.breedingBatchCode }}
                <!-- 2024070800012 -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <img
          v-if="data.productThumbnailImageUrl"
          class="image"
          :src="data.productThumbnailImageUrl"
          alt=""
        />
        <img
          v-if="!data.productThumbnailImageUrl"
          class="kichen"
          src="https://asset.fsytss.com/trace/wcj/icon_product_1.png"
          alt=""
        />
        <img
          v-if="!data.productThumbnailImageUrl"
          class="image"
          src="https://asset.fsytss.com/trace/wcj/icon_product_4.png"
          alt=""
        />
      </div>

      <div class="pushDate">
        <div class="timeDetail">
          <img
            style="width: 18px"
            src="https://asset.fsytss.com/trace/wcj/icon_product_5.png "
            alt=""
          />
          <div class="dateTitle">出栏日期</div>
        </div>
        <div class="date">
          {{ data.fattenPushPopTime ? data.formatFattenPushPopTime : "未出栏" }}
        </div>
      </div>
      <!-- 信息 -->
      <div class="infocontainer">
        <div class="fromAddress">
          <div style="display: flex; align-items: center">
            <img
              style="width: 18px"
              src="https://asset.fsytss.com/trace/wcj/icon_product_7.png "
              alt=""
            />
            <div class="infoLittleTitle">产地</div>
          </div>
          <div class="addressTextTitle">文昌市</div>
          <div class="addressLine"></div>
          <div class="addressDetail">{{ data.sourceAddress }}</div>
        </div>
        <div class="businessAndSpecContainer">
          <div class="businessContainer">
            <img
              class="businessimg1"
              src="../../assets/images/project2/whiteMask.png"
              alt=""
            />
            <img class="businessimg2" :src="business.smallImageUrl" alt="" />
            <div
              style="
                display: flex;
                align-items: center;
                z-index: 3;
                position: relative;
              "
            >
              <img
                style="width: 18px"
                src="https://asset.fsytss.com/trace/wcj/icon_product_6.png "
                alt=""
              />
              <div class="infoLittleTitle">出品企业</div>
            </div>
            <div class="addressText" style="position: relative; z-index: 3">
              {{ data.supplierName }}
            </div>
          </div>
          <div class="specContainer">
            <div style="display: flex; align-items: center">
              <img
                style="width: 18px"
                src="https://asset.fsytss.com/trace/wcj/icon_product_8.png "
                alt=""
              />
              <div class="infoLittleTitle">产品规格</div>
            </div>
            <div class="addressText">{{ data.productSpec }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box"></div>
    <div class="regdown">
      <img src="../../assets/images/project3/regdown.png" alt="" />
    </div>

    <div class="addressAndReport">
      <!-- 产地定位 -->
      <div class="placeAddress">
        <div class="addressTag">产地定位</div>
        <div class="addressInfo">地址：{{ data.sourceAddress }}</div>
        <div class="address2Container">
          <div class="address2" id="container"></div>
        </div>
      </div>
      <!-- 检测报告 -->
      <div
        class="placeAddress"
        style="margin-top: 28px"
        v-if="
          data.manufactureQualityInspectFile ||
          data.manufactureAnimalQuarantineFile
        "
      >
        <div class="addressTag">检测报告</div>
        <div class="address">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-if="data.manufactureQualityInspectFile"
              ><img
                class="bannerImg"
                :src="data.manufactureQualityInspectFile"
                alt=""
                @click="reportClick('manufactureQualityInspectFile')"
            /></van-swipe-item>
            <van-swipe-item v-if="data.manufactureAnimalQuarantineFile"
              ><img
                class="bannerImg"
                :src="data.manufactureAnimalQuarantineFile"
                alt=""
                @click="reportClick('manufactureAnimalQuarantineFile')"
            /></van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <div class="regup">
      <img src="../../assets/images/project3/reg2.png" alt="" />
    </div>
    <!-- 区块链信息 -->
    <div class="box2">
      <div class="areaLineTitle">区块链信息</div>
    </div>
    <div class="areaLine">
      <div class="clickDownLaod" @click="imagePreview(url)">点击放大 ></div>
      <div class="areaContainer" ref="canvasImg">
        <div class="img">
          <div>海南省文昌市畜牧兽医服务中心</div>
          <div class="only">{{ data.manufactureOrderContractId }}</div>
          <div>{{ data.fattenPushPopTime }}</div>
        </div>
      </div>
    </div>
    <!-- 底部技术支持 -->
    <!-- <div class="bottom">一天膳事提供技术服务</div> -->
    <!-- 加载loading -->
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { ImagePreview, Loading } from "vant";
import html2canvas from "html2canvas";
Vue.use(ImagePreview).use(Loading);
export default {
  name: "HomeProductComponent",
  props: [
    "data",
    "latitude",
    "longitude",
    "url",
    "productDetailsFigureImageUrl",
    "business",
  ],
  data() {
    return {
      loadingAll: false,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    reportClick(img) {
      console.log(img);
      let arr = [];
      if (this.data.manufactureQualityInspectFile) {
        arr.push(this.data.manufactureQualityInspectFile);
      }
      if (this.data.manufactureAnimalQuarantineFile) {
        arr.push(this.data.manufactureAnimalQuarantineFile);
      }
      let index = 0;
      if (arr.length == 1) {
        index = 0;
      } else if (img == "manufactureAnimalQuarantineFile") {
        index = 1;
      }
      console.log(index);
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
    async createImage() {
      try {
        const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        this.imgUrl = image;
        ImagePreview([image]);

        this.loadingAll = false;
      } catch (e) {
        this.loadingAll = false;
        throw new Error(e);
      }
    },
    imagePreview(url) {
      this.loadingAll = true;
      this.createImage();
    },
    downloadImage(url, filename) {
      // 创建隐藏的可下载链接
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", filename);
      // 触发点击
      document.body.appendChild(element);
      element.click();
      // 然后移除
      document.body.removeChild(element);
    },
    initMap() {
      if (!this.latitude) return;
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);
      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.image {
  width: 375px;
  height: 240px;
  margin: 0 -21px;
  display: block;
  margin-bottom: 27px;
  margin-top: 9px;
}
.kichen {
  width: 180px;
  height: 44px;
  margin-bottom: -48px;
}
.info_bottom {
  width: 333px;
  height: 124px;
  display: flex;
  background: url("https://asset.fsytss.com/trace/wcj/bg_verify_3.png")
    no-repeat center / cover;
  border-radius: 8px;
  padding-left: 7px;
  padding-top: 35px;
  // padding-bottom: 18px;
  margin: 0 auto;
  margin-top: -20px;
  position: relative;
  margin-bottom: 6px;
  z-index: 2;
  img {
    width: 79px;
    height: 106px;
    margin-top: -10vw;
  }
  .infodetail {
    width: 250px;
    margin-left: 8px;
  }
}

.pushDate {
  width: 319px;
  height: 87px;
  margin: 0 auto;
  background: url("https://asset.fsytss.com/trace/wcj/bg_product_1.png")
    no-repeat center / cover;
  padding: 16px;
  margin-bottom: 8px;
  // pushDate
}
.timeDetail {
  display: flex;
  align-items: center;
}
.dateTitle {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  margin-left: 4px;
}
.date {
  font-weight: 400;
  font-size: 19px;
  color: #231815;
  // line-height: 30px;
}
// 信息
.infocontainer {
  width: 319px;
  height: 197px;
  margin: 0 auto;
  display: flex;
}
.infocontainer2 {
  width: 100%;
  display: flex;
  margin-top: -48px;
}
.fromAddress {
  width: 139px;
  height: 100%;
  margin-right: 8px;
  background: url("https://asset.fsytss.com/trace/wcj/bg_product_2.png")
    no-repeat center / cover;
  padding: 11px 15px;
}
.businessAndSpecContainer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.businessContainer {
  width: 172px;
  height: 95px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  // background: url("../../assets/images/project2/businessBackground.png")
  //   no-repeat center / cover;
  padding: 11px 10px;
  box-sizing: border-box;
  .businessimg1 {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .businessimg2 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.specContainer {
  width: 172px;
  height: 95px;
  background: url("https://asset.fsytss.com/trace/wcj/bg_product_3.png")
    no-repeat center / cover;
  padding: 11px 10px;
}
.addressTextTitle{
  font-size: 14px;
  color: #231815;
  // line-height: 21px;
}
.addressText {
  font-size: 12px;
  color: #231815;
  // line-height: 21px;
  // margin-top: 4px;
}
.addressLine {
  width: 109px;
  height: 1px;
  background-color: red;
  margin: 4px 0;
}
.addressDetail {
  font-size: 12px;
  color: #231815;
  line-height: 15px;
}
// 产地定位
.addressAndReport {
  padding: 26px 28px;
  width: 100vw;
  .placeAddress {
    width: 100%;
    // background: url("../../assets/images/project3/addressContainer.png")
    //   no-repeat center / cover;
    background-image: linear-gradient(to right, #ffd9be, #ffccc7);
    border-radius: 16px;
    overflow: hidden;
    .addressTag {
      font-weight: 900;
      font-size: 17px;
      color: #d82a1f;
      line-height: 20px;
      text-align: center;
      margin-top: 19px;
      margin-bottom: 10px;
    }
    .addressInfo {
      font-size: 14px;
      color: #333333;
      line-height: 16px;
      margin-bottom: 10px;
      text-align: center;
      padding: 0 10px;
    }
    .address {
      background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
      border-radius: 16px;
      padding: 11px 10px;
      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        width: 287px !important;
        margin: 0 auto;
        line-height: 150px;
        text-align: center;
        .bannerImg {
          // width: 100%;
          height: 100%;
          max-width: 200px;
        }
      }
    }
    .address2Container {
      background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
      padding: 11px 10px;
      border-radius: 8px;
      .address2 {
        height: 259px;
        border-radius: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
}

// 区块链信息
.box2 {
  height: 40px;
  margin: 0 -21px;
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 200px;
  overflow: hidden;
}
.areaLineTitle {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  line-height: 20px;
  margin-top: 12px;
  z-index: 9;
  text-align: center;
}
.box2::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 85px;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 100% 100% 0 0;
  background-color: #fd955b;
}
.areaLine {
  background-image: linear-gradient(to bottom, #fd955b, #c12920);
  width: 100vw;
  padding: 5px 27px 16px 27px;
  margin-top: -5px;
  padding-bottom: 130px;
  margin-bottom: -100px;

  .clickDownLaod {
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-bottom: 8px;
    color: #fff;
  }
  .areaContainer {
    background-color: #fff;
    padding: 10px;
    .img {
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
      // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_red_order.png") no-repeat
      //   center / cover;
      background: url("../../assets/images/project1/areaRedOrder.png") no-repeat
        center / cover;
      padding: 18.2vw 7vw 7.2vw 24vw;
      font-size: 12px;
      word-break: break-all;
      color: #231815;
      text-align: right;
      .only {
        margin: 1.5vw 0 0.8vw 0;
      }
    }
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  width: 287px !important;
  height: 220px !important;
  margin: 0 auto;
  line-height: 150px;
  text-align: center;
  margin-top: 24px;
  .bannerImg {
    max-width: 200px;
    height: 100%;
    max-height: 200px;
    object-fit: contain;
  }
}

.box {
  height: 30px;
  margin: 0 -42px;
  margin-bottom: -10px;
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 200px;
  overflow: hidden;
}
.box::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  left: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0 0 50% 50%;
  background-color: #fd955b;
}
.project3TopContainer {
  width: 100vw;
  padding: 16px 21px;
  margin-bottom: -5px;
  padding-bottom: 8px;
  background-image: linear-gradient(to bottom, #c12920, #fd955b);
  .imgcontainer {
    display: flex;
    justify-content: flex-end;
    .img {
      width: 97px;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .img2 {
    width: 100%;
    margin-top: -15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dateAndInfoContainer {
    width: 100%;
    .datecontainer {
      display: flex;
      .datelogo {
        flex: 1;
        img {
          width: 47px;
          height: 47px;
          margin-top: 19px;
        }
      }
      .date {
        width: 196px;
        height: 178px;
        background: url("https://asset.fsytss.com/trace/wcj/bg_verify_4.png")
          no-repeat center / cover;
        .dateDetailContainer {
          display: flex;
          margin-top: 45px;
          justify-content: center;
          align-items: baseline;
          .dateDetail {
            font-weight: bold;
            font-size: 43px;
            color: #ffff94;
          }
          .dateTailText {
            font-weight: bold;
            font-size: 14px;
            color: #ffff94;
          }
        }
        .tianshuText {
          text-align: center;
          font-weight: 500;
          font-size: 13px;
          color: #ffff94;
        }
      }
    }
  }
}
.regdown {
  width: 375px;
  height: 26px;
  img {
    width: 100%;
    height: 100%;
  }
}
.regup {
  width: 375px;
  height: 26px;
  margin-bottom: -8px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>